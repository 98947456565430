<template>
  <div id="app">
    <!-- begin:: Header Mobile -->
    <div id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed">
      <div class="kt-header-mobile__logo">
        <a href="/">
          <img
            alt="Logo"
            style="width: 33px; height: 33px"
            src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F09ecaa_2c390b832a89448697f16121a49e61da_mv2.png?alt=media&token=c25ed621-3189-496a-9910-f9c093c32e14"
          />
        </a>
      </div>
      <div class="kt-header-mobile__toolbar">
        <button
          class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
          id="kt_aside_mobile_toggler"
        >
          <span></span>
        </button>
        <button
          class="kt-header-mobile__toolbar-topbar-toggler"
          id="kt_header_mobile_topbar_toggler"
        >
          <i class="flaticon-more"></i>
        </button>
      </div>
    </div>
    <!-- end:: Header Mobile -->

    <div class="kt-grid kt-grid--hor kt-grid--root">
      <!-- begin:: Page -->
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
      >
        <!-- begin:: Aside -->
        <button class="kt-aside-close" id="kt_aside_close_btn">
          <i class="la la-close"></i>
        </button>
        <div
          v-if="
            $store.getters.getLoggedInStatus &&
            ($router.currentRoute.path === '/dashboard' ||
              $router.currentRoute.path === '/studentProfile' ||
              $router.currentRoute.path === '/teacherProfile' ||
              $router.currentRoute.path === '/videoTraining' ||
              $router.currentRoute.path === '/resources' ||
              $router.currentRoute.path === '/FAQs')
          "
          class="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
          id="kt_aside"
        >
          <!-- begin::Aside Brand -->
          <div class="kt-aside__brand kt-grid__item" id="kt_aside_brand">
            <div class="kt-aside__brand-logo">
              <a href="/">
                <img
                  alt="Logo"
                  style="width: 33px; height: 33px"
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F09ecaa_2c390b832a89448697f16121a49e61da_mv2.png?alt=media&token=c25ed621-3189-496a-9910-f9c093c32e14"
                />
              </a>
            </div>
            <div class="kt-aside__brand-tools">
              <!-- <button
                class="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left"
                id="kt_aside_toggler"
              >
                <span></span>
              </button> -->
            </div>
          </div>
          <!-- end:: Aside Brand -->
          <!-- begin:: Aside Menu -->
          <div
            class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
            style="height: 100vh"
            id="kt_aside_menu_wrapper"
          >
            <div
              id="kt_aside_menu"
              class="kt-aside-menu"
              data-ktmenu-vertical="1"
              data-ktmenu-scroll="1"
              data-ktmenu-dropdown-timeout="500"
            >
              <ul class="kt-menu__nav">
                <li
                  v-bind:class="{
                    'kt-menu__item--active':
                      $router.currentRoute.path === '/dashboard',
                  }"
                  class="kt-menu__item"
                  aria-haspopup="true"
                >
                  <a href="/dashboard" class="kt-menu__link">
                    <i class="kt-menu__link-icon flaticon2-pie-chart-1"></i>
                    <span class="kt-menu__link-text">Dashboard</span>
                  </a>
                </li>
                <li
                  class="kt-menu__item"
                  aria-haspopup="true"
                  v-show="showNewStudentBtn"
                >
                  <a
                    href="javascript:;"
                    class="kt-menu__link"
                    data-toggle="modal"
                    data-target="#newStudentModal"
                  >
                    <i class="kt-menu__link-icon flaticon2-plus"></i>
                    <span class="kt-menu__link-text">Add Student</span>
                  </a>
                </li>
                <!-- <li class="kt-menu__item" aria-haspopup="true">
                  <a href="/dashboard#students" class="kt-menu__link">
                    <i class="kt-menu__link-icon flaticon2-avatar"></i>
                    <span class="kt-menu__link-text">Your Students</span>
                  </a>
                </li> -->
                <li
                  v-bind:class="{
                    'kt-menu__item--active':
                      $router.currentRoute.path === '/videoTraining',
                  }"
                  class="kt-menu__item"
                  aria-haspopup="true"
                  v-show="paid"
                >
                  <a href="/videoTraining" class="kt-menu__link">
                    <i class="kt-menu__link-icon flaticon2-photo-camera"></i>
                    <span class="kt-menu__link-text">Video Training</span>
                  </a>
                </li>
                <li class="kt-menu__item" aria-haspopup="true">
                  <a
                    href="javascript:;"
                    class="kt-menu__link"
                    data-toggle="modal"
                    data-target="#supportModal"
                    v-show="paid"
                  >
                    <i class="kt-menu__link-icon flaticon-support"></i>
                    <span class="kt-menu__link-text">Support</span>
                  </a>
                </li>
                <li class="kt-menu__item" aria-haspopup="true" v-show="paid">
                  <a href="/resources" class="kt-menu__link">
                    <i class="kt-menu__link-icon flaticon2-box"></i>
                    <span class="kt-menu__link-text">Resources</span>
                  </a>
                </li>
                <li class="kt-menu__item" aria-haspopup="true" v-show="paid">
                  <a href="/FAQs" class="kt-menu__link">
                    <i
                      class="kt-menu__link-icon flaticon-questions-circular-button"
                    ></i>
                    <span class="kt-menu__link-text">FAQ</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- end:: Aside Menu -->
        </div>
        <!-- end:: Aside -->

        <!-- begin:: Wrapper -->
        <div
          class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
          id="kt_wrapper"
        >
          <div
            id="kt_header"
            v-if="$store.getters.getLoggedInStatus"
            style="background-color: #001065; height: 100px"
            class="kt-header kt-grid__item toolBar"
          >
            <div class="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
              <div
                id="kt_header_menu"
                class="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-"
              >
                <ul class="kt-menu__nav"></ul>
                <div
                  class="kt-header-menu-wrapper"
                  id="kt_header_menu_wrapper"
                  style="opacity: 1"
                >
                  <div
                    id="kt_header_menu"
                    class="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-"
                  >
                    <ul class="kt-menu__nav">
                      <li
                        class="kt-menu__item kt-menu__item--rel kt-menu__item--active"
                        v-if="
                          $store.getters.getLoggedInStatus &&
                          $router.currentRoute.path === '/classroom/teacher'
                        "
                      >
                        <a
                          href="javascript:;"
                          @click="endClassSession"
                          class="kt-menu__link kt-menu__toggle"
                        >
                          <span
                            class="kt-menu__link-text"
                            id="finishClassSession"
                          >
                            <!-- <i class="flaticon2-back"></i>  -->
                            End Class
                          </span>
                        </a>
                      </li>
                      <!-- <li
                        class="kt-menu__item"
                        v-if="
                          $store.getters.getLoggedInStatus &&
                          $router.currentRoute.path === '/classroom/teacher'
                        "
                      >
                        <span class="kt-menu__link-text text-white">
                          <i class="la la-user"></i>
                          {{ $store.getters.getClassroomInfoStudentName }}
                        </span>
                      </li> -->
                      <!-- <li
                        class="kt-menu__item"
                        v-if="
                          $store.getters.getLoggedInStatus &&
                          $router.currentRoute.path === '/classroom/teacher'
                        "
                      >
                        <div class="text-center">
                          <span class="kt-menu__link-text text-white">
                            <div v-html="classroomInfoProgress"></div>
                          </span>
                        </div>
                      </li> -->

                      <li
                        class="kt-menu__item"
                        v-if="
                          $store.getters.getLoggedInStatus &&
                          $router.currentRoute.path === '/classroom/teacher'
                        "
                      >
                        <div class="text-center">
                          <div
                            class="kt-slider__label"
                            style="max-width: 250px"
                          >
                            <!-- {{ procedureName | lowerCase }} -->
                            <!-- <div v-html="classroomInfoProgress"></div> -->
                            <h5>
                              {{ classroomInfoProgress }} <br />
                              <span v-show="'Introduction' != procedureName">{{
                                procedureName
                              }}</span>
                            </h5>
                          </div>
                        </div>
                      </li>

                      <li
                        class="kt-menu__item"
                        v-if="
                          $store.getters.getLoggedInStatus &&
                          $router.currentRoute.path === '/classroom/teacher' &&
                          classroomInfoProgress !== 'Sandbox'
                        "
                      >
                        <div class="text-center">
                          <a
                            href="javascript:;"
                            @click="prevProd"
                            class="btn btn-primary"
                            >Previous Procedure</a
                          >
                        </div>
                      </li>
                      <li
                        class="kt-menu__item"
                        v-if="
                          $store.getters.getLoggedInStatus &&
                          $router.currentRoute.path === '/classroom/teacher' &&
                          classroomInfoProgress !== 'Sandbox'
                        "
                      >
                        <div class="text-center">
                          <a
                            href="javascript:;"
                            @click="nextProd"
                            class="btn btn-primary"
                            >Next Procedure</a
                          >
                        </div>
                      </li>

                      <li
                        class="kt-menu__item"
                        v-if="
                          $store.getters.getLoggedInStatus &&
                          $router.currentRoute.path === '/classroom/teacher'
                        "
                      >
                        <div class="text-center">
                          <a
                            href="javascript:;"
                            class="btn"
                            :class="{
                              'btn-dark': isInstructionsVisible === false,
                              'btn-primary': isInstructionsVisible === true,
                            }"
                            checked="checked"
                            @click="toggleInstructions"
                          >
                            <span v-show="isInstructionsVisible">Hide</span>
                            <span v-show="!isInstructionsVisible">Show</span>
                            Text
                          </a>
                        </div>
                      </li>

                      <li
                        class="kt-menu__item"
                        v-if="
                          $store.getters.getLoggedInStatus &&
                          $router.currentRoute.path === '/classroom/teacher'
                        "
                      >
                        <div class="text-center">
                          <a
                            href="javascript:;"
                            class="btn"
                            :class="{
                              'btn-dark': isInstructionsVisible === false,
                              'btn-primary': isInstructionsVisible === true,
                            }"
                            @click="toggleHighlighting"
                            >{{
                              isHighlighting ? "Stop Highlighting" : "Highlight"
                            }}</a
                          >
                        </div>
                      </li>

                      <canvas
                        v-if="isHighlighting"
                        id="overlay"
                        ref="overlayCanvas"
                        @mousedown="startDraw"
                        @mousemove="draw"
                        @mouseup="endDraw"
                      ></canvas>

                      <li
                        class="kt-menu__item"
                        v-if="
                          $store.getters.getLoggedInStatus &&
                          $router.currentRoute.path === '/classroom/teacher'
                        "
                      >
                        <div class="text-center">
                          <a
                            href="javascript:;"
                            class="btn btn-primary"
                            @click="launchConfetti"
                            >Confetti!</a
                          >
                        </div>
                      </li>

                      <li
                        class="kt-menu__item"
                        v-if="
                          $store.getters.getLoggedInStatus &&
                          $router.currentRoute.path === '/classroom/teacher'
                        "
                      >
                        <div class="text-center">
                          <a
                            href="javascript:;"
                            class="btn btn-primary btn-icon"
                            data-toggle="modal"
                            data-target="#classroomSettingsModal"
                            title="Settings"
                          >
                            <i class="la la-cogs"></i>
                          </a>
                        </div>
                      </li>
                      <li
                        class="kt-menu__item"
                        v-if="
                          $store.getters.getLoggedInStatus &&
                          $router.currentRoute.path === '/classroom/teacher'
                        "
                      >
                        <div class="text-center">
                          <a
                            href="javascript:;"
                            class="btn btn-primary btn-icon"
                            data-toggle="modal"
                            data-target="#gamesModal"
                            title="Games"
                          >
                            <i class="fas fa-dice"></i>
                          </a>
                        </div>
                      </li>

                      <li class="kt-menu__item">
                        <div class="text-center">
                          <a
                            href="javascript:;"
                            class="btn btn-primary btn-icon"
                            @click="askFullscreen()"
                            title="Toggle fullscreen"
                          >
                            <i
                              class="icon-2x text-dark-50 flaticon-browser"
                            ></i>
                          </a>
                        </div>
                      </li>
                      <li class="kt-menu__item">
                        <div class="text-center">
                          <h2
                            v-if="
                              $router.currentRoute.path === '/dashboard' ||
                              $router.currentRoute.path === '/studentProfile' ||
                              $router.currentRoute.path === '/teacherProfile' ||
                              $router.currentRoute.path === '/videoTraining' ||
                              $router.currentRoute.path === '/resources' ||
                              $router.currentRoute.path === '/FAQs' ||
                              $router.currentRoute.path === '/admin'
                            "
                          >
                            Foundation in Sounds™
                          </h2>
                          <h5 v-else>Foundation in Sounds™</h5>
                        </div>
                      </li>
                      <li class="kt-menu__item">
                        <div v-if="ready">
                          <ProcedureSelector
                            :student="student"
                            :isClassroom="true"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- begin:: Header Topbar -->
            <div class="kt-header__topbar">
              <!--begin: User Bar -->
              <div class="kt-header__topbar-item kt-header__topbar-item--user">
                <div
                  class="kt-header__topbar-wrapper"
                  data-toggle="dropdown"
                  data-offset="0px, 0px"
                >
                  <div class="kt-header__topbar-user">
                    <!-- <span class="kt-header__topbar-username kt-hidden-mobile"
                      >Hi</span
                    >
                    <span class="kt-header__topbar-username kt-hidden-mobile">{{
                      displayName
                    }}</span> -->
                    <img :src="profilePhotoUrl" />
                    <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
                    <span
                      class="kt-badge kt-badge--username kt-badge--lg kt-badge--brand kt-hidden kt-badge--bold"
                      >S</span
                    >
                  </div>
                </div>

                <div
                  class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-sm"
                >
                  <div
                    class="kt-user-card kt-margin-b-40 kt-margin-b-30-tablet-and-mobile"
                    style="
                      background-image: url(./components/assets/media/misc/head_bg_sm.jpg);
                    "
                  >
                    <div class="kt-user-card__wrapper">
                      <div class="kt-user-card__pic">
                        <img :src="profilePhotoUrl" />
                      </div>
                      <div class="kt-user-card__details">
                        <div class="kt-user-card__name">
                          {{ displayName }}
                        </div>
                        <div class="kt-user-card__position">
                          {{ position }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul class="kt-nav kt-margin-b-10">
                    <li class="kt-nav__item">
                      <a href="/teacherProfile" class="kt-nav__link">
                        <span class="kt-nav__link-icon">
                          <i class="flaticon2-calendar-3"></i>
                        </span>
                        <span class="kt-nav__link-text">My Profile</span>
                      </a>
                    </li>
                    <li
                      v-if="adminStatus && accountType === 'learningCenter' || accountType === 'tutorAdmin'"
                      class="kt-nav__item kt-nav__item--custom kt-margin-t-15"
                    >
                      <a
                        href="/admin"
                        class="btn btn-label-brand btn-upper btn-sm btn-bold"
                        >Sign back in as admin</a
                      >
                    </li>
                    <!-- <li
                      v-else-if="adminStatus && accountType != 'learningCenter'"
                      class="kt-nav__item kt-nav__item--custom kt-margin-t-15"
                    >
                      <div>
                        <a
                          href="javascript:;"
                          @click="openBilling"
                          id="newBillingButton"
                          class="btn btn-brand btn-bold btn-upper btn-font-sm"
                        >
                          <i class="la la-bank"></i> Billing
                        </a>
                      </div>
                    </li> -->
                    <li
                      class="kt-nav__item kt-nav__item--custom kt-margin-t-15"
                    >
                      <a
                        href="javascript:;"
                        @click="logOut()"
                        class="btn btn-label-brand btn-upper btn-sm btn-bold"
                        >Sign Out</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <!--end: User Bar -->
            </div>
            <!-- end:: Header Topbar -->
          </div>

          <div class="container">
            <div
              class="alert alert-success"
              role="alert"
              v-if="$store.getters.getInfoSuccess"
            >
              {{ $store.getters.getInfoSuccess }}
            </div>
            <div
              class="alert alert-danger"
              role="alert"
              v-if="$store.getters.getInfoDanger"
            >
              {{ $store.getters.getInfoDanger }}
            </div>
          </div>

          <!-- Start Support Modal -->
          <div
            class="modal fade"
            id="supportModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="supportModalCenterTitle"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title" id="supportModalCenterTitle">
                    <i class="kt-menu__link-icon flaticon-support"></i>
                    Contact Support
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <br />
                  <h1 class="text-center">+1 719 476-0189</h1>
                  <br />
                  <br />
                  <h4 class="text-center text-muted">
                    <b>-- Or send us an email below --</b>
                  </h4>
                  <br />
                  <br />

                  <form
                    id="fs-frm"
                    name="simple-contact-form"
                    class="kt-form"
                    accept-charset="utf-8"
                  >
                    <fieldset id="fs-frm-inputs" class="form-group">
                      <label for="full-name">Full Name</label>
                      <input
                        class="form-control"
                        v-model="fullName"
                        type="text"
                        name="name"
                        id="full-name"
                        placeholder="First and Last"
                        required
                      />
                      <br />
                      <label for="email-address">Email Address</label>
                      <input
                        class="form-control"
                        v-model="email"
                        type="email"
                        name="_replyto"
                        id="email-address"
                        placeholder="email@email.com"
                        required
                      />
                      <br />
                      <label for="message">Message</label>
                      <textarea
                        class="form-control"
                        v-model="message"
                        rows="5"
                        name="message"
                        id="message"
                        placeholder="Your question here."
                        required
                      ></textarea>
                    </fieldset>
                    <a
                      href="javascript:;"
                      @click="submitContactForm()"
                      class="btn btn-primary"
                      >Submit</a
                    >
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- End Support Modal -->

          <!-- Start new student Modal -->
          <newStudent></newStudent>
          <!-- End new student Modal -->

          <!-- Start games Modal -->
          <gamesModal></gamesModal>
          <!-- End games Modal -->

          <!-- Sound stop light modal -->
          <soundStopLightModal></soundStopLightModal>
          <!-- end Sound stop light modal -->

          <!-- Views -->
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from './components/firebaseInit'
// import db from './components/firebaseDB'

import ProcedureSelector from "./components/procedureSelector";

import newStudent from "./components/newStudent";
import gamesModal from "./components/gamesModal";
import soundStopLightModal from "./components/soundStopLightModal";

// import Sticky from 'sticky-js'
// global.Sticky = Sticky;// IMPORTANT DO NOT REMOVE

import PerfectScrollbar from "./components/assets/vendors/general/perfect-scrollbar/dist/perfect-scrollbar.js";
global.PerfectScrollbar = PerfectScrollbar; // IMPORTANT DO NOT REMOVE

// import Cookies from './components/assets/vendors/general/js-cookie/src/js.cookie.js'
// global.Cookies = Cookies// IMPORTANT DO NOT REMOVE

import moment from "./components/assets/vendors/general/moment/min/moment.min.js";
global.moment = moment;

//import datepicker from './components/assets/vendors/general/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
//global.datepicker = datepicker;

//console.log("ASDSADDSASDA", datepicker);

//$.fn.datepicker.defaults.zIndexOffset = 10;

// import picker from './components/assets/vendors/general/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// global.picker = picker;

//require('./components/assets/demo/default/base/scripts.bundle.js');//KTUtil
// import KTUtil from './components/assets/demo/default/base/scripts.bundle.js'
// global.KTUtil = KTUtil // IMPORTANT DO NOT REMOVE
//console.log("KTUtil", KTUtil);

//import KTLib from './components/assets/app/bundle/app.bundle.js'
//global.KTLib = KTLib // IMPORTANT DO NOT REMOVE
//console.log("KTLib", KTLib);

//require('./components/assets/demo/default/base/scripts.bundle.js');//KTUtil

//require('./components/assets/vendors/custom/fullcalendar/fullcalendar.bundle.js');

//require('./components/assets/app/bundle/app.bundle.js');//KTLib

//require('./components/assets/app/custom/general/dashboard.js');

import defaultProfileImage from "./components/assets/media/users/default.jpg";

import screenfull from "screenfull";

import axios from "axios";

const upperCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

if (localStorage.getItem("classroomInfoProgress") === null) {
  localStorage.setItem("classroomInfoProgress", "");
}

export default {
  name: "app",
  data() {
    return {
      displayName: "",
      adminStatus: false,
      showNewStudentBtn: false,
      ready: false,
      profilePhotoUrl: "",
      stepName: "",
      newStep: 0,
      procedureName: "",
      position: "Teacher",
      classroomInfoProgress: upperCase(
        localStorage.getItem("classroomInfoProgress") //.replace(",", ",<br/>")
      ),
      showPicArea: false,
      fullName: "",
      email: "",
      message: "",
      version: null,
      student: {},
      isInstructionsVisible: true,
      isHighlighting: false,
      isDrawing: false,
      context: null,
      accountType: "",
      paid: false,
    };
  },
  components: {
    newStudent,
    gamesModal,
    soundStopLightModal,
    ProcedureSelector,
  },
  async mounted() {
    const that = this;
    var user = firebase.auth().currentUser;

    if (user) {
      that.displayName = user.displayName;
      that.profilePhotoUrl =
        null !== user.photoURL ? user.photoURL : defaultProfileImage;
    }

    window.addEventListener("classroomInfoProgress-changed", (event) => {
      // this.data = event.detail.storage;
      that.classroomInfoProgress = upperCase(
        localStorage.getItem("classroomInfoProgress") //.replace(",", ",<br/>")
      );
    });

    // Wait 2 seconds for the DOM to load
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // // Get user's role in custom claims
    // const tokenResult = await firebase
    //   .auth()
    //   .currentUser.getIdTokenResult(true);
    // const isAdmin = !!tokenResult.claims.admin;

    // that.showNewStudentBtn = isAdmin;

    firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        that.displayName = user.displayName;
        that.profilePhotoUrl =
          null !== user.photoURL ? user.photoURL : defaultProfileImage;

        const token = await firebase.auth().currentUser.getIdToken(true);

        // Get user's role in custom claims
        const tokenResult = await firebase
          .auth()
          .currentUser.getIdTokenResult(true);
        const isAdmin = !!tokenResult.claims.admin;
        const isTutor = !!tokenResult.claims.tutor;
        const accountType = tokenResult.claims.accountType;
        that.accountType = accountType;

        that.adminStatus = isAdmin;

        console.log("account type", accountType);

        that.$store.dispatch("setAuthStatus", true);
        that.$store.dispatch("setAdminStatus", isAdmin);
        localStorage.setItem("userID", user.uid);

        // Now load user's document if they are an admin and check for paid status
        const userDoc = await firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get()
          .catch((error) => {
            console.log("Error getting document:", error);
          });

        const userData = userDoc.data();

        that.paid = userData.paid;
        console.log("tutor paid", that.paid);
        // that.showNewStudentBtn = userData.paid; // Because everyone should only add students ince they've "paid"

        if (that.accountType == 'homeschooler') {
          that.showNewStudentBtn = true;
        }

      } else {
        that.$store.dispatch("setAuthStatus", false);
        that.$store.dispatch("setAdminStatus", false);
        localStorage.removeItem("userID");
      }
    });

    that.$root.$on("procedureName", (name) => {
      that.procedureName = name;
    });

    that.$root.$on("showPicAreaRoot", (isActive) => {
      that.showPicArea = isActive;
    });

    that.$root.$on("student-paid", (name) => {
      alert("student paid");
      that.paid = true;
    });

    if (that.$router.currentRoute.path === "/classroom/teacher") {
      that.loadStudentSelector();
    } else {
      that.ready = false;
    }

    that.getVersion();
  },
  methods: {
    launchConfetti() {
      this.$root.$emit("launchConfetti");
    },
    nextProd() {
      this.$root.$emit("nextProd");
    },
    prevProd() {
      this.$root.$emit("prevProd");
    },
    toggleHighlighting() {
      this.isHighlighting = !this.isHighlighting;

      if (this.isHighlighting) {
        // Update .imgContainer img's position to initial position
        $(".imgContainer img").css("position", "initial !important");
      } else {
        // Update .imgContainer img's position to absolute
        $(".imgContainer img").css("position", "absolute");
      }

      this.$nextTick(() => {
        // Wait for Vue to update the DOM
        if (this.isHighlighting) {
          this.setupCanvas();
        }
      });
    },
    setupCanvas() {
      const overlayCanvas = this.$refs.overlayCanvas;
      overlayCanvas.width = window.innerWidth;
      overlayCanvas.height = window.innerHeight;

      this.context = overlayCanvas.getContext("2d");
      this.context.strokeStyle = "rgb(255, 255, 0, 0.5)";
      this.context.lineWidth = 5;
      this.context.lineJoin = "round";
      this.context.lineCap = "round";
    },
    startDraw(event) {
      if (!this.isHighlighting) return;

      const rect = this.$refs.overlayCanvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      this.isDrawing = true;
      this.context.beginPath();
      this.context.moveTo(x, y);
    },
    draw(event) {
      if (!this.isDrawing || !this.context) return;

      const rect = this.$refs.overlayCanvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      this.context.lineTo(x, y);
      this.context.stroke();
    },

    endDraw() {
      this.isDrawing = false;
    },
    logOut() {
      const that = this;

      firebase
        .auth()
        .signOut()
        .then(function () {
          // Sign-out successful.
          that.$store.dispatch("setAuthStatus", false);
          that.adminStatus = false;
          that.$store.dispatch("setAdminStatus", false);
          localStorage.removeItem("userID");
          that.$router.push("/");
        })
        .catch(function (error) {
          // An error happened.
        });
    },
    endClassSession() {
      this.$root.$emit("endClassSession");
    },
    askFullscreen() {
      if (document.fullscreenElement) {
        this.fullscreen(false);
      } else {
        this.fullscreen(true);
      }
    },
    async loadStudentSelector() {
      const that = this;
      // Basically two issues that I'm awre of:
      // 1. This code only runs once, but needs to run each time the route is changed, or at least each time we're on the classroom page
      // 2. We need to add in a "start lesson" btn for the selected lesson

      const querySnapshot = await db
        .collection("users")
        .doc(localStorage.getItem("userID"))
        .collection("students")
        .doc(localStorage.getItem("classroomInfoStudentID"))
        .get()
        .catch(console.error);

      that.student = {
        studentFullName: querySnapshot.data().studentFullName,
        studentReadableProgress: that.capitalizeFirstLetter(
          querySnapshot.data().studentReadableProgress
        ),
        id: querySnapshot.data().myUid,
        currentLesson: querySnapshot.data().currentLesson,
        totalPoints: querySnapshot.data().totalPoints,
        totalTries: querySnapshot.data().totalTries,
        disabled: querySnapshot.data().disabled,
        needsRenewal: querySnapshot.data().needsRenewal,
      };

      that.ready = true;
    },
    fullscreen(changeTo) {
      console.log("screenfull.isEnabled", screenfull.isEnabled);
      if (true == changeTo) {
        if (screenfull.isEnabled) {
          screenfull.request();
        } else {
          document.body.requestFullscreen();
          requestFullscreen();
        }
        sessionStorage.setItem("isFullscreen", true);
      } else {
        document.exitFullscreen();
        screenfull.exit();
        sessionStorage.setItem("isFullscreen", false);
      }
    },
    async submitContactForm() {
      await axios
        .post("/api/messageUs", {
          fullName: this.fullName,
          replyTo: this.email,
          message: this.message,
        })
        .then(() => {
          alert("Message submitted!");
          $("#supportModal").modal("hide");
        });
    },
    async getVersion() {
      try {
        const response = await fetch("/api/version");
        const versionData = await response.json();
        this.version = versionData.version;
        console.log(
          "%cVersion: " + this.version,
          "color: white; background-color: blue; font-size: 20px; padding: 10px; border-radius: 5px;"
        );
      } catch (error) {
        console.error("Error fetching version:", error);
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleInstructions() {
      this.isInstructionsVisible = !this.isInstructionsVisible;
      this.$root.$emit("toggleInstructionsVisibility");
    },
    async openBilling() {
      const that = this;

      // Set mouse cursor to loading spinner
      window.document.body.style.cursor = "wait";

      const token = await firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true);

      // Get secure billing portal link from server
      await axios
        .post("/api/billing/portal", {
          token: token,
        })
        .then((response) => {
          // Set mouse cursor back to default
          window.document.body.style.cursor = "default";

          window.open(response.data.url, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });

      // Set mouse cursor back to default
      window.document.body.style.cursor = "default";
    },
  },
  watch: {
    async $route(to, from) {
      const that = this;

      // You can access the new route through the 'to' parameter
      console.log("Route changed from:", from.path, "to:", to.path);

      if (to.path === "/classroom/teacher") {
        that.loadStudentSelector();
      } else {
        that.ready = false;
      }
    },
    showPicArea(value) {
      this.$root.$emit("showPicAreaRoot", value);
    },
  },
  filters: {
    lowerCase: function (value) {
      if (!value) return "";
      value = value.toString();
      value = value.toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  computed: {
    // classroomInfoProgress() {
    // 	// const CIP = localStorage.getItem('classroomInfoProgress').replace(',', ',<br/>');
    // 	return localStorage.getItem('classroomInfoProgress').replace(',', ',<br/>');
    // }
  },
};
</script>

<style>
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.kt-menu__item {
  padding-left: 10px !important;
}

.kt-header {
  border: none !important;
}

#kt_aside,
.kt-aside-menu,
.kt-aside__brand,
.kt-menu__link:hover {
  background-color: #001065 !important;
}

.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__heading,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link {
  background-color: #2d244b !important;
}

.kt-widget-3.kt-widget-3--brand,
.kt-widget-3.kt-widget-3--success {
  border-radius: 25px;
}

a {
  color: #001065;
}

#finishClassSession {
  color: #001065 !important;
}

.kt-menu__item a {
  color: #001065 !important;
}

.kt-portlet,
.kt-option {
  border-radius: 25px;
}

.kt-header__topbar-user {
  color: #001065 !important;
}

.btn-primary,
.kt-header
  .kt-header-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link {
  color: #fff !important;
  background-color: #80c5bd !important;
  border-color: #80c5bd !important;
}
.btn-primary:hover,
.kt-header
  .kt-header-menu
  .kt-menu__nav
  > .kt-menu__item.kt-menu__item--active
  > .kt-menu__link:hover {
  background-color: #6bbbb2 !important;
  border-color: #6bbbb2 !important;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #67b6ad !important;
  border-color: #67b6ad !important;
}

.kt-header
  .kt-header__topbar
  .kt-header__topbar-item:hover.kt-header__topbar-item--user
  .kt-header__topbar-user,
.kt-header
  .kt-header__topbar
  .kt-header__topbar-item.show.kt-header__topbar-item--user
  .kt-header__topbar-user {
  color: #fff;
  background-color: #80c5bd;
  border-color: #80c5bd;
}

.kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-user {
  border-radius: 0.25rem;
}

.kt-menu__item {
  color: white;
}

.kt-slider .kt-slider__head .kt-slider__nav .kt-slider__nav-next:active,
.kt-slider .kt-slider__head .kt-slider__nav .kt-slider__nav-prev:active {
  background-color: #001065;
}

.kt-header-menu .kt-menu__nav > .kt-menu__item {
  padding: 0px;
}

#kt_header {
  margin-left: 0px;
}
@media (min-width: 1025px) {
  .kt-header-menu {
    margin-left: 12px;
  }
}

img {
  border-radius: 5px !important;
}

.dot {
  border-radius: 5px !important;
}

#kt_aside_footer {
  position: fixed;
  bottom: 0px;
  width: 260px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #80c5bd;
}

input:focus + .slider {
  box-shadow: 0 0 1px #80c5bd;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#showTiles {
  padding-right: 10px;
}

#showTilesSwitch {
  top: 2px;
}

.btn-icon i {
  color: #001065 !important;
}

.kt-header__topbar-username {
  color: white !important;
}

#supportModalCenterTitle {
  font-size: 24px;
}

#fs-frm input,
label,
textarea {
  font-size: 18px !important;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999; /* make sure this is above everything else in your app */
  margin-top: 76px;
}

/* #highlightCanvas {
  position: fixed;
  top: 76px;
  left: 0;
  z-index: 1000; 
  pointer-events: none; 
} */

.highlight-box {
  position: absolute;
  background-color: rgba(255, 255, 0, 0.5); /* Yellow with 50% opacity */
  pointer-events: none;
}
</style>
