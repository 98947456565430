<template>
  <div v-if="show" class="overlay" @click.stop>
    <div class="overlay-modal">
      <a href="javascript:;" @click="pay" class="modal-button">Pay to Unlock</a>
      <p>
        <br />
        Pay $100 once to unlock the admin panel <br />
        
      </p>
      <div v-show="hasNotSent">
        <h4 style="margin-top: 40px">- Or -</h4>
        <p>
          <br />
          Verify that you've previously paid for this content by entering <br />
          your full name and email address below.
        </p>
        <div>
          <input type="text" placeholder="Full Name" v-model="fullName" />
          <input type="text" placeholder="Email Address" v-model="email" />
          <button class="modal-button" @click="sendVerifyEmail">Verify</button>
        </div>
      </div>
      <div v-show="!hasNotSent">
        <p>
          <br />
          We've notified our support team with the information you provided.
          <br />
          We'll get back to you as soon as possible.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { io } from "socket.io-client";

const socket = io();

export default {
  name: "Overlay",
  data() {
    return {
      paymentLinks: "",
      hasNotSent: true,
      fullName: "",
      email: "",
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    paymentLink: {
      type: String,
      default: "",
    },
  },
  async mounted() {
    const that = this;

    // On socket connected
    socket.on("connect", () => {
      console.log("socket connected");
      console.log("socket ID", socket.id);
    });

    // Listen for changes to the payment info
    socket.on("payment_update", async (message) => {
      message = JSON.parse(message);
      console.log("payment_update", message);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  },
  methods: {
    async pay() {
      const that = this;

      // Send request to server with socket ID
      // Then open the payment link in a new tab
      const idToken = await firebase.auth().currentUser.getIdToken(true);

      const res = await axios.post("/api/socket-id", {
        token: idToken,
        socketId: socket.id,
      });

      if (!res.data.success) {
        console.error("Error getting document:", res.data.error);
      }

      const paymentLink = this.$props.paymentLink;

      console.log("paymentLink", paymentLink);

      // Open payment link in a new tab
      window.open(paymentLink, "_self").focus();
    },
    async sendVerifyEmail() {
      // Post to server to send email
      const res = await axios.post("/api/sendVerifyEmail", {
        fullName: this.fullName,
        email: this.email,
      });

      // If successful, show success message
      if (res.status === 200) {
        this.hasNotSent = false;
      }
    },
  },
};
</script>

<style>
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffffb5;
  /* opacity: 0.6; maybe fix this?? */
  z-index: 10;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-modal {
  background-color: #fff !important;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 11;
  opacity: 1 !important;
  text-align: center; /* Centers inline-block elements like buttons */
}

.overlay-modal input[type="text"] {
  padding: 10px; /* Adjust padding to match the button's size */
  margin: 4px 2px;
  border: 1px solid #ccc; /* Add a border to match typical input styles */
  border-radius: 4px;
  width: calc(100% - 22px); /* Adjust width to account for padding and border */
}

.modal-button {
  background-color: #001065;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%; /* Make the button full width */
}

p {
  font-size: 18px;
  color: black !important;
}
</style>
